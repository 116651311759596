<script lang="ts" setup>
import UnstyledButton from '@/Components/UnstyledButton.vue'
import { Method } from '@inertiajs/core'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    type?: 'button' | 'submit' | 'reset'
    size?: 'lg',
    href?: string
    method?: Method
    as?: string
    disabled?: boolean
  }>(),
  {
    type: 'submit',
  }
)

const styleClasses = computed(() => {
  if (props.disabled) {
    return 'bg-gray-400 text-gray-600 hover:bg-gray-400 focus:bg-gray-400 active:bg-gray-400 focus:ring-gray-500 cursor-not-allowed'
  }

  return 'bg-primary-500 text-black hover:bg-primary-300 focus:bg-primary-300 active:bg-primary-700 focus:ring-primary-500'
})

</script>
<template>
  <UnstyledButton
    :type="type"
    :size="size"
    :href="href"
    :method="method"
    :as="as"
    :class="styleClasses"
    :disabled="disabled"
    class="inline-flex items-center border border-transparent font-caps font-medium uppercase focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 transition ease-in-out duration-150">
    <slot />
  </UnstyledButton>
</template>
